import * as React from "react";
import styled from "styled-components"
import { Seo, TitleComponent } from "../components/atoms";
import { PostComponent } from "../components/molecules";
import { LayoutComponent } from "../components/organisms";

const Tag = styled.div``

const TagPage = ({ pageContext }) => {
  const tag = pageContext.tag
  const title = tag + " に関する記事"
  const canonical = "/tags/" + tag
  const posts = pageContext.posts.map((post) => {
    return (
      <PostComponent key={post.title}
        title={post.title}
        canonical={post.canonical}
        tags={post.tags}
        publishedAt={post.publishedAt} />
    )
  })
  return (
    <LayoutComponent hasTitle={true}>
      <Tag>
        <TitleComponent title={title} href={canonical} />
        {posts}
      </Tag>
    </LayoutComponent>
  )
}

export default TagPage
export const Head = ({ pageContext }) => (
  <Seo title={ pageContext.tag + "に関する記事" }
    description={ "当ブログ内の" + pageContext.tag + "に関する記事を集めたページです。" }
    pathname={ "/tags/" + pageContext.tag } />
)
